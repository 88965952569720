<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          size="medium"
          :color="getIconColor(availability)"
          v-bind="attrs"
          v-on="on"
          @mouseenter="fetchStatus"
        >
          {{ getIconName(availability) }}
        </v-icon>
      </template>
      <template v-if="status.length > 0">
        <div class="calendar-element">
          {{ availability }}
          <div v-if="statusMessage">
            {{ statusMessage.message.content }}
          </div>
        </div>
      </template>
    </v-tooltip>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Teams",
  props: {
    activity: {
      required: true,
    },
    availability: {
      required: true,
    },
    statusMessage: {
      required: true,
    },
    microsoftEntraId: {
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      status: [],
    };
  },
  methods: {
    async fetchStatus() {
      this.loading = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/persons/teams/${this.microsoftEntraId}/presence/`,
        );

        if (response.data && response.data.length > 0) {
          const firstStatus = response.data[0];
          this.status = [firstStatus];

          this.availability = firstStatus.availability;
        } else {
          this.status = [];
        }
      } catch (error) {
        console.error("Error fetching status:", error);
        this.status = [];
      } finally {
        this.loading = false;
      }
    },
    getIconName(availability) {
      switch (availability) {
        case "AvailableIdle":
          return "mdi-checkbox-blank-circle";
        case "Available":
          return "mdi-checkbox-blank-circle";
        case "Away":
          return "mdi-clock-time-three-outline";
        case "Busy":
          return "mdi-minus-circle";
        case "BusyIdle":
          return "mdi-minus-circle";
        case "DoNotDisturb":
          return "mdi-minus-circle";
        case "Offline":
          return "mdi-arrow-left-circle";
        default:
          return "mdi-help-circle";
      }
    },
    getIconColor(availability) {
      switch (availability) {
        case "Available":
          return "green";
        case "AvailableIdle":
          return "green";
        case "Away":
          return "orange";
        case "BusyIdle":
          return "orange";
        case "DoNotDisturb":
          return "red";
        case "Busy":
          return "red";
        case "Offline":
          return "purple";
        default:
          return "grey";
      }
    },
  },
};
</script>
