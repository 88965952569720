import axios from "axios";

// initial state
const state = () => ({
  listData: [],
});

// getters
const getters = {
  getListData(state) {
    // Return the data from the state
    return state.listData;
  },
};

// actions
const actions = {
  async fetchListData({ commit }, area) {
    const endpoint = process.env.VUE_APP_API_BASE_URL + "/list_data/";

    const params = {};
    if (area) {
      params.area = area;
    }

    const config = {
      params: params,
    };

    try {
      let response = await axios.get(endpoint, config);

      commit("SET_LIST_DATA", response.data.list_data);

      return response;
    } catch (error) {
      console.error(error);
    }
  },
};

// mutations
const mutations = {
  SET_LIST_DATA(state, listData) {
    state.listData = listData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
