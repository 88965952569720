<template>
  <div>
    <v-tooltip v-if="hasUpcomingAppointments" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon size="medium" v-bind="attrs" v-on="on" :color="'red'">
          mdi-calendar
        </v-icon>
      </template>
      <div>
        <template>
          <div
            v-for="calendarItem in calendar"
            :key="calendarItem.id"
            class="calendar-element"
          >
            {{ formatTime(calendarItem.start) }}: {{ calendarItem.name }}
          </div>
        </template>
      </div>
    </v-tooltip>
    <v-icon v-if="!hasUpcomingAppointments" size="medium" :color="'green'">
      mdi-calendar
    </v-icon>
  </div>
</template>

<script>
export default {
  name: "Calendar",
  props: {
    calendar: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      showAppointments: false,
    };
  },
  computed: {
    hasUpcomingAppointments() {
      return this.calendar && this.calendar.length > 0;
    },
  },
  methods: {
    formatTime(dateTimeString) {
      const date = new Date(dateTimeString).toLocaleTimeString("de-CH", {
        hour: "2-digit",
        minute: "2-digit",
      });

      if (date === "00:00") {
        return "Ganztags";
      }

      return date;
    },
  },
  mounted() {
    this.$el.addEventListener("mouseenter", this.handleHover);
    this.$el.addEventListener("mouseleave", this.handleLeave);
  },
  beforeDestroy() {
    this.$el.removeEventListener("mouseenter", this.handleHover);
    this.$el.removeEventListener("mouseleave", this.handleLeave);
  },
};
</script>
