import axios from "axios";

// initial state
const state = () => ({
  areas: [],
});

// getters
const getters = {};

// actions
const actions = {
  async getAreas({ commit }, params) {
    const endpoint = process.env.VUE_APP_API_BASE_URL + "/areas/";

    try {
      const response = await axios.get(endpoint, {
        params: params,
      });

      if (response.data) {
        commit("SET_AREAS", response.data.areas);
      }

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async createArea({ commit }, data) {
    const endpoint = process.env.VUE_APP_API_BASE_URL + "/areas/";

    try {
      const response = await axios.post(endpoint, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data) {
        commit("SET_AREA", response.data.area);
      }

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

// mutations
const mutations = {
  SET_AREAS(state, areas) {
    state.areas = areas;
  },

  SET_AREA: (state, area) => {
    state.areas.push(area);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
