<template>
  <v-app-bar app elevation="0" height="64" color="primary" dark>
    <v-menu offset-y min-width="200" left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-icon size="28">mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ this.$keycloak.fullName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-group>
          <v-list-item @click="$keycloak.logoutFn">
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <!-- Spacer and Logo only on larger screens -->
    <v-spacer />
    <div class="justify-center">
      <v-toolbar-title class="text-md-center ingesa-headline">
        zuständigkeiten.
      </v-toolbar-title>
    </div>
    <v-spacer />
    <img
      class="d-none d-sm-block ma-3"
      :src="require('/public/ingesa_logo_weiss_png.png')"
      height="40"
    />

    <!-- Drawer for mobile navigation -->
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list>
        <v-list-item link @click="$router.push('/')">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Zuständigkeiten</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="$router.push('/mitarbeitende')">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Mitarbeitende</v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          v-if="$keycloak.resourceAccess['zkl-api']['roles'].includes('writer')"
          @click="$router.push('/admin/personen')"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Admin</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-app-bar>
</template>

<script>
export default {
  name: "Header",
  data: () => ({
    drawer: false,
  }),
};
</script>
