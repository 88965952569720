import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#BD1020",
        secondary: "#b0bec5",
        anchor: "#787878",
      },
    },
  },
});

export default vuetify;
