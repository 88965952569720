import axios from "axios";

// initial state
const state = () => ({
  tasks: [],
});

// getters
const getters = {};

// actions
const actions = {
  async getTasks({ commit }, area) {
    const params = {};
    if (area) {
      params.area = area;
    }

    try {
      const data = await axios.get(
        process.env.VUE_APP_API_BASE_URL + "/tasks/",
        {
          params: params,
        },
      );
      commit("SET_TASKS", data.data.tasks);
    } catch (error) {
      console.error(error);
    }
  },
};

// mutations
const mutations = {
  SET_TASKS(state, tasks) {
    state.tasks = tasks;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
