<template>
  <v-container>
    <v-responsive>
      <h1 class="ma-6">Zuständigkeiten</h1>
      <v-row class="ma-2 justify-end"></v-row>
      <v-row class="ma-2">
        <div class="col-sm-3 custom-col-menu">
          <Sidebar></Sidebar>
        </div>
        <div class="col-sm-9 custom-col-main">
          <v-card elevation="1" outlined tile class="pa-2">
            <v-card-text>
              <v-autocomplete
                v-model="selectedPerson"
                :items="formattedPersons"
                :disabled="formattedPersons.length === 0 || loading"
                label="Mitarbeitende"
                item-value="id"
                item-text="fullName"
                outlined
                dense
              />
              <v-autocomplete
                v-model="selectedTasks"
                :items="sortedFormattedTasks"
                :disabled="formattedTasks.length === 0 || loading"
                item-value="id"
                item-text="name"
                label="Tätigkeit"
                return-object
                outlined
                dense
                @change="fetchAreas"
              >
                <template v-slot:item="data">
                  <v-list-item>
                    <v-list-item-content
                      :class="{ 'greyed-out': !data.item.selected }"
                    >
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <template v-if="data.index < sortedFormattedTasks.length - 1">
                    <v-divider
                      v-if="
                        data.item.selected &&
                        !sortedFormattedTasks[data.index + 1].selected
                      "
                    />
                  </template>
                </template>
              </v-autocomplete>
              <v-autocomplete
                v-model="selectedAreas"
                :items="formattedAreas"
                :disabled="formattedAreas.length === 0 || loading"
                item-value="id"
                item-text="name"
                label="Gebiet"
                return-object
                multiple
                clearable
                outlined
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    v-if="index < 5"
                    :key="index"
                    close
                    @click:close="remove(item)"
                  >
                    {{ item.name }}
                  </v-chip>
                  <span v-if="index === 5 && selectedAreas.length > 5">
                    +{{ selectedAreas.length - 5 }} weitere
                  </span>
                </template>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <div class="d-flex align-center justify-start">
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                      <v-checkbox
                        v-model="data.item.is_main"
                        :input-value="data.item.is_main"
                        @change="selectItemFromCheckbox(data.item, $event)"
                        label="Hauptverantwortlicher"
                      ></v-checkbox>
                    </div>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-row class="d-flex justify-content-center">
                <v-col cols="12" md="6">
                  <div v-if="changedAreas.length > 0">
                    <div v-for="area in changedAreas" :key="area.id">
                      {{ area }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="text-right">
                  <v-btn color="primary" class="mt-3" @click="savePersonAreas">
                    Speichern
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-row>
    </v-responsive>
  </v-container>
</template>

<script>
import axios from "axios";
import Sidebar from "../Sidebar.vue";

export default {
  name: "AdminResponsibilities",
  components: { Sidebar },
  data() {
    return {
      persons: [],
      selectedPerson: null,
      tasks: [],
      selectedTasks: null,
      areas: [],
      selectedAreas: null,
      changedAreas: [],
      loading: false,
    };
  },
  computed: {
    formattedPersons() {
      return this.persons.map((person) => ({
        id: person.id,
        fullName: `${person.name} ${person.surname}`,
      }));
    },
    formattedAreas() {
      return this.areas.map((area) => ({
        id: area.id,
        name: area.name,
      }));
    },
    formattedTasks() {
      const taskMap = new Map();

      this.tasks.forEach((task) => {
        if (!taskMap.has(task.name)) {
          taskMap.set(task.name, { ids: [], selected: false });
        }
        const entry = taskMap.get(task.name);
        entry.ids.push(task.id);
        if (task.selected) {
          entry.selected = true;
        }
      });

      const result = [];
      taskMap.forEach((value, name) => {
        result.push({
          id: value.ids.join(","),
          name,
          selected: value.selected,
        });
      });

      return result;
    },

    sortedFormattedTasks() {
      return [...this.formattedTasks].sort((a, b) => b.selected - a.selected);
    },
  },
  methods: {
    async fetchPersons() {
      this.loading = true;
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/persons/`,
        );
        this.persons = data;
      } catch (error) {
        console.error("Error fetching persons:", error);
        this.persons = [];
      } finally {
        this.loading = false;
      }
    },
    async fetchTasks() {
      if (!this.selectedPerson) return;
      this.loading = true;
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/persons/${this.selectedPerson}/tasks`,
        );
        this.tasks = data.map((task) => ({
          ...task,
          selected: task.selected || false,
        }));
        this.selectedTasks = this.tasks.filter((task) => task.selected);
      } catch (error) {
        console.error("Error fetching tasks:", error);
        this.tasks = [];
      } finally {
        this.loading = false;
      }
    },
    async fetchAreas() {
      console.log(this.selectedTasks.id);
      if (!this.selectedTasks) return;
      this.loading = true;
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/persons/${this.selectedPerson}/areas`,
          {
            params: { taskId: this.selectedTasks.id },
          },
        );
        this.areas = data;
        this.selectedAreas = this.areas.filter((area) => area.selected);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.areas = [];
        } else {
          console.error("Error fetching areas:", error);
        }
      } finally {
        this.loading = false;
      }
    },
    async savePersonAreas() {
      if (!this.selectedPerson || !this.selectedTasks || !this.selectedAreas)
        return;
      try {
        await axios.put(
          `${process.env.VUE_APP_API_BASE_URL}/persons/${this.selectedPerson}/areas`,
          {
            taskId: this.selectedTasks.id,
            areas: this.selectedAreas,
          },
        );
      } catch (error) {
        console.error("Error saving tasks:", error);
      } finally {
        this.clearFields(true);
      }
    },
    clearFields(all) {
      this.areas = [];
      this.selectedAreas = [];
      this.changedAreas = [];
      this.tasks = [];
      this.selectedTasks = null;
      if (all) {
        this.selectedPerson = null;
      }
    },
    remove(item) {
      const removalIndex = this.selectedAreas.findIndex(
        (area) => area.id === item.id,
      );
      if (removalIndex !== -1) {
        this.selectedAreas.splice(removalIndex, 1);
      }
    },
    selectItemFromCheckbox(item, isChecked) {
      const foundIndex = this.selectedAreas.findIndex(
        (area) => area.id === item.id,
      );

      if (isChecked) {
        if (foundIndex === -1) {
          this.selectedAreas.push({
            ...this.selectedAreas[foundIndex],
            is_main: true,
          });
        } else {
          this.selectedAreas.push({
            ...item,
            is_main: true,
          });
        }
      } else {
        // If checkbox is unchecked, only update is_main to false
        if (foundIndex !== -1) {
          this.selectedAreas.splice(foundIndex, 1, {
            ...this.selectedAreas[foundIndex],
            is_main: false,
          });
        }
      }
    },
  },
  watch: {
    selectedPerson(newVal) {
      if (newVal) {
        this.clearFields(false);
        this.fetchTasks();
      }
    },
    selectedTasks(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal == Object) this.fetchAreas();
      }
    },
  },
  mounted() {
    this.fetchPersons();
  },
};
</script>

<style>
.greyed-out {
  color: #b0bec5;
}
</style>
