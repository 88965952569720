<template>
  <v-list-item three-line>
    <div class="d-flex flex-column align-items-center">
      <v-list-item-avatar class="mt-5 ml-4 mb-0">
        <v-img :src="person.photo"></v-img>
      </v-list-item-avatar>
      <div class="d-flex ma-2">
        <v-list-item-action class="ma-1" v-if="person.calendar">
          <Calendar :calendar="person.calendar"></Calendar>
        </v-list-item-action>
        <v-list-item-action class="ma-1" v-if="person.teamsStatus">
          <Teams
            :activity="person.teamsStatus.activity"
            :availability="person.teamsStatus.availability"
            :status-message="person.teamsStatus.statusMessage"
            :microsoft-entra-id="person.teamsStatus.id"
          ></Teams>
        </v-list-item-action>
      </div>
    </div>
    <v-list-item-content>
      <v-list-item-title>
        {{ person.name }} {{ person.surname }} - ({{ person.short }})
      </v-list-item-title>
      <v-list-item-subtitle v-if="isMain">
        <span>Hauptkontakt</span>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-else>
        <span>Stellvertretung</span>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="person.phone">
        <strong>Telefon: </strong>
        <a :href="getPhoneLink(person.phone)">{{ person.phone }}</a>
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <strong>E-Mail: </strong>
        <a :href="getMailtoLink(person.email)">{{ person.email }}</a>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Calendar from "./Calendar.vue";
import Teams from "./Teams.vue";

export default {
  name: "PersonCard",
  components: { Calendar, Teams },
  props: {
    person: {
      type: Object,
      required: true,
    },
    isMain: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    getPhoneLink(phone) {
      return `tel:${encodeURIComponent(phone)}`;
    },
    getMailtoLink(email) {
      return `mailto:${encodeURIComponent(email)}`;
    },
  },
};
</script>
