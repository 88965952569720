import Vue from "vue";
import Vuex from "vuex";
import tasks from "@/store/tasks";
import areas from "@/store/areas";
import persons from "@/store/persons";
import listData from "@/store/listData";
import businessUnits from "@/store/businessUnits";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    tasks,
    persons,
    areas,
    listData,
    businessUnits,
  },
});
