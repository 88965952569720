<template>
  <v-card elevation="1" outlined tile class="pa-2">
    <v-list dense>
      <v-list-item
        v-for="item in filteredItems"
        :key="item.value"
        @click="item.submenu ? (item.expanded = !item.expanded) : null"
      >
        <v-list-item-content>
          <v-list-item-title>
            <router-link :to="{ path: item.link }">{{ item.value }}</router-link>
          </v-list-item-title>
        </v-list-item-content>

        <v-expand-transition>
          <v-list v-if="item.submenu && item.expanded" dense>
            <v-list-item
              v-for="subItem in getFilteredSubmenu(item)"
              :key="subItem.value"
              @click.stop
            >
              <v-list-item-content>
                <v-list-item-title>
                  <router-link :to="{ path: subItem.link }">
                    {{ subItem.value }}
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expand-transition>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    items: [
      { value: "Zuständigkeiten", link: "/" },
      { value: "Mitarbeitende", link: "/personen" },
      {
        value: "Administration",
        link: "",
        submenu: [
          { value: "Personen", link: "/admin/personen" },
          { value: "Zuständigkeiten", link: "/admin/zustaendigkeiten" },
        ],
        expanded: false,
      },
    ],
  }),
  computed: {
    hasWriterRole() {
      return this.$keycloak.resourceAccess['zkl-api']['roles'].includes('writer');
    },
    filteredItems() {
      // Filter main menu items based on roles
      return this.items.filter(item => {
        // If the item has a submenu, check if any submenu item should be displayed
        if (item.submenu) {
          item.submenu = this.getFilteredSubmenu(item);
          return item.submenu.length > 0;
        }
        // Otherwise, always include the item (adjust this as needed for other roles)
        return true;
      });
    }
  },
  methods: {
    checkActive(item) {
      if (this.$route.path === item.link) {
        return true;
      }
      if (item.submenu) {
        return item.submenu.some(
          (subItem) => this.$route.path === subItem.link,
        );
      }
      return false;
    },
    getFilteredSubmenu(item) {
      // Return submenu items only if the user has the writer role
      return this.hasWriterRole ? item.submenu : [];
    },
  },
  mounted() {
    this.items.forEach((item) => {
      if (item.submenu) {
        item.expanded = this.checkActive(item);
      }
    });
  },
};
</script>
