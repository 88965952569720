// router/index.js
import Vue from "vue";
import VueRouter from "vue-router";
import Responsibilites from "./components/Responsibilities.vue";
import AdminResponsibilities from "./components/Admin/Responsibilities.vue";
import Persons from "./components/Persons.vue";
import AdminPersons from "./components/Admin/Persons.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/personen",
    component: Persons,
    name: "persons",
    meta: { requiresAuth: true, roles: ["reader"] },
  },
  {
    path: "/",
    component: Responsibilites,
    name: "zkl-list",
    meta: { requiresAuth: true, roles: ["reader"] },
  },
  {
    path: "/admin/zustaendigkeiten",
    component: AdminResponsibilities,
    name: "admin-responsibilities",
    meta: { requiresAuth: true, roles: ["writer"] },
  },
  {
    path: "/admin/personen",
    component: AdminPersons,
    name: "admin-persons",
    meta: { requiresAuth: true, roles: ["writer"] },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = Vue.prototype.$keycloak.authenticated;
  const userRoles = Vue.prototype.$keycloak.resourceAccess["zkl-api"]["roles"];

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next("/login");
    } else {
      const requiredRoles = to.meta.roles;
      const hasRequiredRoles = userRoles.some((role) =>
        requiredRoles.includes(role),
      );
      if (!hasRequiredRoles) {
        next("/unauthorized");
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
