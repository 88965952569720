import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store/index";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import router from "./routes";
import axios from "axios";

Vue.config.productionTip = false;

Vue.use(VueKeyCloak, {
  init: {
    // Use 'login-required' to always require authentication
    // If using 'login-required', there is no need for the router guards in router.js
    onLoad: "login-required",
    // silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html"
  },
  config: {
    url: "https://auth.ingesa.ch",
    realm: "ingesa-web-users",
    clientId: process.env.VUE_APP_CLIENT_ID,
  },
  logout: {
    redirectUri: process.env.VUE_APP_LOGOUT_REDIRECT_URL,
  },
  onReady: (keycloak) => {
    axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${keycloak.token}`;
      return config;
    });

    new Vue({
      vuetify,
      store,
      router,
      render: (h) => h(App),
    }).$mount("#app");
  },
});
