import axios from "axios";

const state = {
  persons: [],
  loading: false,
  loadingInBackground: false,
  locations: [
    { name: "Andelfingen" },
    { name: "Seuzach" },
    { name: "Wetzikon" },
    { name: "Elgg" },
  ],
  selectedLocation: null,
  allPersons: [],
};

const mutations = {
  SET_PERSONS(state, payload) {
    state.persons = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_LOADING_IN_BACKGROUND(state, payload) {
    state.loadingInBackground = payload;
  },
  SET_SELECTED_LOCATION(state, payload) {
    state.selectedLocation = payload;
  },
  SET_ALL_PERSONS(state, payload) {
    state.allPersons = payload;
  },
  APPEND_ALL_PERSONS(state, payload) {
    state.allPersons = [...state.allPersons, ...payload].filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
    );
  },
};

const actions = {
  async fetchDataForLocation({ commit, dispatch, state }, { location }) {
    if (
      location &&
      state.allPersons.some((person) => person.address.startsWith(location))
    ) {
      const filteredPersons = state.allPersons.filter((person) =>
        person.address.startsWith(location),
      );
      commit("SET_PERSONS", filteredPersons);
    } else {
      commit("SET_LOADING", true);
      try {
        const url = location
          ? `${process.env.VUE_APP_API_BASE_URL}/persons/?location=${encodeURIComponent(
              location,
            )}`
          : `${process.env.VUE_APP_API_BASE_URL}/persons/`;
        const response = await axios.get(url);
        const personDetails = await dispatch(
          "extractPersonDetails",
          response.data,
        );
        const emails = personDetails.map((person) => person.email);
        const entraIds = personDetails.map((person) => person.entraId);
        const [appointments, teamsStatus] = await Promise.all([
          dispatch("fetchAppointments", emails),
          dispatch("fetchTeamsStatus", entraIds),
        ]);
        const mergedData = await dispatch("mergeData", {
          persons: response.data,
          appointments,
          teamsStatus,
        });
        commit("SET_PERSONS", mergedData);
        commit("APPEND_ALL_PERSONS", mergedData);
      } catch (error) {
        console.error("Error fetching data for location:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    }
    setTimeout(() => {
      dispatch("fetchAllPersonsInBackground");
    }, 0);
  },

  async fetchAllPersonsInBackground({ commit, dispatch, state }) {
    const remainingLocations = state.locations.filter(
      (location) =>
        !state.allPersons.some((person) =>
          person.address.startsWith(location.name),
        ),
    );
    try {
      commit("SET_LOADING_IN_BACKGROUND", true);
      const responses = await Promise.all(
        remainingLocations.map((location) =>
          axios.get(
            `${process.env.VUE_APP_API_BASE_URL}/persons/?location=${encodeURIComponent(
              location.name,
            )}`,
          ),
        ),
      );

      const processPersonsPromises = responses.map(async (response) => {
        const personDetails = await dispatch(
          "extractPersonDetails",
          response.data,
        );
        const emails = personDetails.map((person) => person.email);
        const entraIds = personDetails.map((person) => person.entraId);

        const [appointments, teamsStatus] = await Promise.all([
          dispatch("fetchAppointments", emails),
          dispatch("fetchTeamsStatus", entraIds),
        ]);

        const mergedData = await dispatch("mergeData", {
          persons: response.data,
          appointments,
          teamsStatus,
        });
        commit("APPEND_ALL_PERSONS", mergedData);
      });

      await Promise.all(processPersonsPromises);
    } catch (error) {
      console.error("Error fetching all persons in the background:", error);
    } finally {
      commit("SET_LOADING_IN_BACKGROUND", false);
    }
  },

  async fetchAllPersons({ commit }) {
    commit("SET_LOADING", true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/persons/`,
      );
      commit("SET_ALL_PERSONS", response.data);
    } catch (error) {
      console.error("Error fetching all persons:", error);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  extractPersonDetails(_, persons) {
    return persons.map((person) => {
      const entraId = person.photo ? person.photo.split(".jpg")[0] : null;
      return {
        email: person.email,
        entraId: entraId,
      };
    });
  },
  
  async fetchAppointments(_, emails) {
    if (!emails.length) return {};
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/persons/calendar/events/batch`,
        { emails },
      );
      return Object.keys(response.data).reduce((acc, email) => {
        if (Array.isArray(response.data[email])) {
          let events = response.data[email].map((event) => ({
            id: event.id,
            start: event.start,
            name: event.name,
          }));
          events.sort((a, b) => new Date(a.start) - new Date(b.start));
          acc[email] = events;
        } else {
          console.error(
            "Expected an array for:",
            email,
            "got:",
            response.data[email],
          );
          acc[email] = [];
        }
        return acc;
      }, {});
    } catch (error) {
      console.error("Error fetching appointments:", error);
      return {};
    }
  },

  async fetchTeamsStatus(_, entraIds) {
    const validEntraIds = entraIds.filter((id) => id !== null);
    if (!validEntraIds.length) return {};
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/persons/teams/presence/batch/`,
        { entraIds: validEntraIds },
      );
      return response.data.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
    } catch (error) {
      console.error("Error fetching teams status:", error);
      return {};
    }
  },
  async mergeData({ dispatch }, { persons, appointments, teamsStatus }) {
    const updatedPersons = await Promise.all(
      persons.map(async (person) => {
        const personId = person.photo ? person.photo.split(".jpg")[0] : null;
        const photo = await dispatch("getPhotoUrl", person);
        return {
          ...person,
          photo: photo,
          calendar: appointments[person.email] || [],
          teamsStatus: teamsStatus[personId] || null,
        };
      }),
    );
    return updatedPersons;
  },

  async getPhotoUrl(_, person) {
    const baseUrlRaw = process.env.VUE_APP_API_BASE_URL.replace("/v1", "");
    const avatarUrl = "https://www.gravatar.com/avatar";
    if (!person.photo) {
      return avatarUrl;
    }
    const imageUrl = `${baseUrlRaw}/static/persons/photos/${person.photo}`;
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Failed to convert image to Base64:", error);
      return avatarUrl;
    }
  },
};

const getters = {
  filteredPersons: (state) => {
    return state.persons
      .filter((person) => {
        const hasNameAndSurname = person.name && person.surname;
        if (!state.selectedLocation || state.selectedLocation.name === "Alle") {
          return hasNameAndSurname;
        }
        return (
          hasNameAndSurname &&
          person.address.startsWith(state.selectedLocation.name)
        );
      })
      .sort((a, b) =>
        a.surname.toLowerCase().localeCompare(b.surname.toLowerCase()),
      );
  },
  allPersons: (state) => state.allPersons,
  selectedLocation: (state) => state.selectedLocation,
  isDataLoaded: (state) => state.persons.length > 0,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

function setupIntervals() {
  setInterval(
    () => {
      console.log("Fetching teams status for all locations");
      actions.fetchTeamsStatus(
        null,
        state.allPersons.map((person) => person.entraId),
      );
    },
    10 * 60 * 1000,
  );

  setInterval(
    () => {
      console.log("Fetching appointments for all persons");
      actions.fetchTeamsStatus(
        null,
        state.allPersons.map((person) => person.email),
      );
    },
    60 * 60 * 1000,
  );
}

setupIntervals();
