<template>
  <v-container>
    <v-responsive>
      <h1 class="ma-6">Zuständigkeiten</h1>
      <v-row class="ma-2 justify-end"></v-row>
      <v-row class="ma-2">
        <div class="col-sm-3 custom-col-menu">
          <Sidebar></Sidebar>
        </div>
        <div class="col-sm-9 custom-col-main">
          <v-card tile class="pa-2">
            <v-card-text>
              <v-select
                outlined
                hide-details
                no-data-text="Daten laden..."
                v-model="selectedArea"
                :items="areas"
                item-text="name"
                item-value="id"
                label="Gebiet auswählen"
                return-object
                dense
                flat
                @change="onAreaChange"
              ></v-select>
            </v-card-text>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4" xs="12">
                  <v-select
                    ref="businessUnitNameFilterInput"
                    outlined
                    clearable
                    @click:clear="clearBusinessUnitNameFilterInput"
                    :items="businessUnitNames"
                    v-model="businessUnitNameFilterInput"
                    no-data-text="Daten laden..."
                    :persistent-hint="true"
                    dense
                    hint="Geschäftsfeld suchen"
                    flat
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" xs="12">
                  <v-select
                    ref="clearTaskFilterInput"
                    outlined
                    clearable
                    :items="taskNames"
                    v-model="taskNameFilterInput"
                    no-data-text="Daten laden..."
                    :persistent-hint="true"
                    dense
                    hint="Tätigkeit suchen"
                    flat
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" xs="12">
                  <v-text-field
                    @keydown="shouldExpandGroup"
                    outlined
                    v-model="personNameFilterInput"
                    :persistent-hint="true"
                    dense
                    hint="Name oder Kürzel suchen"
                    flat
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card flat v-if="loading"></v-card>
          <v-card elevation="1" outlined tile class="mt-4 pa-2" flat v-else>
            <v-card-title>
              <v-icon large class="mr-4"
                >mdi-format-list-bulleted-square</v-icon
              >
              RESULTAT
            </v-card-title>
            <v-card-text v-if="filteredData.length">
              <v-list dense nav expand>
                <v-list-group
                  v-for="businessUnit in filteredData"
                  :key="businessUnit.id"
                  active-class="grey lighten-4"
                  no-action
                  v-model="groupOpened"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title
                        class="ingesa_headline font-weight-bold"
                        >{{
                          businessUnit.business_unit_name.toUpperCase()
                        }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </template>
                  <v-list-group
                    v-for="(task, index) in businessUnit.responsibilities"
                    :key="task.id"
                    :value="true"
                    no-action
                    sub-group
                    v-model="subGroupOpened"
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title class="ingesa_sublines">{{
                          task.task
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-row dense class="ma-2 ml-15">
                      <v-col
                        v-if="!task.persons.length"
                        class="d-flex"
                        cols="12"
                        md="6"
                        sm="12"
                        xs="12"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="mb-2"
                            >Keine Zuständigkeit gefunden</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-col>
                      <v-col
                        v-for="(person, index) in task.persons"
                        v-else
                        :key="person.id"
                        :class="getPersonClass(task.persons.length, index)"
                        cols="12"
                        md="6"
                        sm="12"
                        xs="12"
                      >
                        <PersonCard
                          :person="person.details"
                          :is-main="person.is_main"
                        ></PersonCard>
                      </v-col>
                      <v-col class="d-flex" cols="12">
                        <v-divider
                          v-if="index < businessUnit.length - 1"
                        ></v-divider>
                      </v-col>
                    </v-row>
                  </v-list-group>
                </v-list-group>
              </v-list>
            </v-card-text>
            <v-card-text v-else>
              Diese Filterkombination ergibt keine Ergebnisse.
            </v-card-text>
          </v-card>
        </div>
      </v-row>
    </v-responsive>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Sidebar from "./Sidebar.vue";
import PersonCard from "./PersonCard.vue";

export default {
  name: "zkl-list",
  components: { Sidebar, PersonCard },
  data() {
    return {
      loading: false,
      selectedArea: null,
      // filter
      businessUnitNameFilterInput: "",
      taskNameFilterInput: "",
      personNameFilterInput: "",
      taskNames: [],
      businessUnitNames: [],
      // group state
      groupStatus: false,
    };
  },
  mounted() {
    this.fetchAllPersonsInBackground();
  },
  created() {
    this.getAreas({ only_active: true });
  },
  watch: {
    selectedArea(newValue) {
      this.loading = true;
      this.fetchListData(newValue.id).then(() => {
        this.getTaskNames();
        this.getBusinessUnitNames();
        this.loading = false;
      });
    },
    businessUnitNameFilterInput() {
      this.getBusinessUnitNames();
      this.getTaskNames();
      this.groupOpened;
    },
    taskNameFilterInput() {
      this.getTaskNames();
    },
  },
  methods: {
    ...mapActions("listData", ["fetchListData"]),
    ...mapActions("areas", ["getAreas"]),
    ...mapActions("persons", ["fetchAllPersonsInBackground"]),

    shouldExpandGroup() {
      return (
        this.taskNameFilterInput ||
        this.businessUnitNameFilterInput ||
        this.personNameFilterInput
      );
    },

    clearBusinessUnitNameFilterInput() {
      this.taskNameFilterInput = "";
    },

    onAreaChange(value) {
      this.businessUnitNameFilterInput = "";
      this.taskNameFilterInput = "";
      this.personNameFilterInput = "";
      this.$emit("area-change", value);
    },

    async getBusinessUnitNames() {
      const data = await this.$store.getters["listData/getListData"];
      this.businessUnitNames = data.map(
        (businessUnit) => businessUnit.business_unit_name,
      );
    },

    async getTaskNames() {
      const data = await this.$store.getters["listData/getListData"];
      if (this.businessUnitNameFilterInput) {
        const filteredData = data.find(
          (businessUnit) =>
            businessUnit.business_unit_name ===
            this.businessUnitNameFilterInput,
        );
        this.taskNames = filteredData
          ? filteredData.responsibilities.map((task) => task.task)
          : [];
      } else {
        this.taskNames = data.flatMap((businessUnit) =>
          businessUnit.responsibilities.map((task) => task.task),
        );
      }
    },

    filterByBusinessUnit(data) {
      return data.filter((d) =>
        d.business_unit_name
          .toLowerCase()
          .includes(this.businessUnitNameFilterInput.toLowerCase()),
      );
    },

    filterByTaskName(data) {
      return data.filter((d) => {
        const filteredTasks = d.responsibilities.filter((task) =>
          task.task
            .toLowerCase()
            .includes(this.taskNameFilterInput.toLowerCase()),
        );
        return filteredTasks.length > 0
          ? { ...d, responsibilities: filteredTasks }
          : false;
      });
    },

    filterByPersonName(data) {
      return data
        .map((d) => {
          const filteredResponsibilities = d.responsibilities
            .map((task) => {
              const filteredPersons = this.filterPersonsByName(task.persons);
              return { ...task, persons: filteredPersons };
            })
            .filter((task) => task.persons.length > 0);
          return { ...d, responsibilities: filteredResponsibilities };
        })
        .filter((d) => d.responsibilities.length > 0);
    },

    filterPersonsByName(persons) {
      return persons.filter((person) =>
        ["name", "surname", "short"].some((key) =>
          person.details[key]
            .toLowerCase()
            .includes(this.personNameFilterInput.toLowerCase()),
        ),
      );
    },

    getPersonClass(personCount, index) {
      const baseClass = "d-flex max-height";
      const smAndDown = this.$vuetify.breakpoint.smAndDown;
      if (personCount === 1 && smAndDown) return `${baseClass} mb-0 pt-0`;
      if (personCount > 1 && smAndDown) {
        return index === personCount - 1
          ? `${baseClass} pt-0 mt-7`
          : `${baseClass} pt-0`;
      }
      return baseClass;
    },
  },
  computed: {
    ...mapState("areas", ["areas"]),
    ...mapState("listData", ["listData"]),
    ...mapState("persons", ["allPersons"]),

    groupOpened() {
      return this.shouldExpandGroup();
    },

    subGroupOpened() {
      return this.shouldExpandGroup();
    },

    filteredData() {
      let result = this.$store.getters["listData/getListData"];

      // Update person details
      result.forEach((element) => {
        element.responsibilities.forEach((task) => {
          task.persons = task.persons.map((person) => {
            const matchedPerson = this.allPersons.find(
              (p) => p.email === person.details.email,
            );
            return matchedPerson
              ? { ...person, details: matchedPerson }
              : person;
          });
        });
      });

      if (this.businessUnitNameFilterInput) {
        result = this.filterByBusinessUnit(result);
      }

      if (this.taskNameFilterInput) {
        result = this.filterByTaskName(result);
      }

      if (this.personNameFilterInput) {
        result = this.filterByPersonName(result);
      }

      return result;
    },
  },
};
</script>
